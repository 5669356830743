import {relativeRoute} from "@/helpers/relativeRoute";

const Footer = () => {

  const year = new Date().getFullYear();

  const handlePrivacySettings = () => {
    window.Didomi.preferences.show()
  }
  return <>


    <footer className="bg-white footer__links">
      <div>
        <div className="about">
          <h3>Mensenlinq</h3>
          <a href={relativeRoute('over-ons') }>Over ons</a>
          <a href={relativeRoute('contact') }>Contact opnemen</a>
          {/*{{--            <a href={relativeRoute('obituary.index') }>Overlijdensberichten</a>--}}*/}

          {/*{{--            <h3>Adverteren</h3>--}}*/}
          {/*{{--            <a href={relativeRoute('advertise') }>Adverteren op Mensenlinq.nl</a>--}}*/}
          {/*{{--            <a href={relativeRoute('create') }>Rouwadvertentie plaatsen</a>--}}*/}
        </div>
        <div className="obituary-shortcuts">
          <h3>Overlijdensberichten</h3>
          <a href={relativeRoute('obituary.index', {'papers': ['leeuwarder-courant']}) }>Leeuwarder Courant</a>
          <a href={relativeRoute('obituary.index', {'papers': ['dagblad-van-het-noorden']}) }>Dagblad van het Noorden</a>
          <hr/>
          <a href={relativeRoute('obituary.index', {'province': 'drenthe'}) }>Drenthe</a>
          <a href={relativeRoute('obituary.index', {'province': 'flevoland'}) }>Flevoland</a>
          <a href={relativeRoute('obituary.index', {'province': 'friesland'}) }>Friesland</a>
          <a href={relativeRoute('obituary.index', {'province': 'gelderland'}) }>Gelderland</a>
          <a href={relativeRoute('obituary.index', {'province': 'groningen'}) }>Groningen</a>
          <a href={relativeRoute('obituary.index', {'province': 'limburg'}) }>Limburg</a>
          <a href={relativeRoute('obituary.index', {'province': 'noord-brabant'}) }>Noord-Brabant</a>
          <a href={relativeRoute('obituary.index', {'province': 'noord-holland'}) }>Noord-Holland</a>
          <a href={relativeRoute('obituary.index', {'province': 'overijssel'}) }>Overijssel</a>
          <a href={relativeRoute('obituary.index', {'province': 'utrecht'}) }>Utrecht</a>
          <a href={relativeRoute('obituary.index', {'province': 'zeeland'}) }>Zeeland</a>
          <a href={relativeRoute('obituary.index', {'province': 'zuid-holland'}) }>Zuid-Holland</a>
        </div>
        <div className="socials">
          <h3>Volg je ons?</h3>
          <a href="https://www.facebook.com/mensenlinq/">Volg ons op Facebook</a>
          <a href="https://twitter.com/mensenlinq">Volg ons op X</a>
          <a href="https://nl.linkedin.com/company/mensenlinq">Volg ons op LinkedIn</a>

          <h3>Statements</h3>
          <a href="https://www.mediahuisnoord.nl/verkoopvoorwaarden">Algemene Voorwaarden</a>
          <a href="https://www.mediahuisnoord.nl/disclaimer">Disclaimer</a>
          <a href="https://mediahuisnoord.nl/cookieverklaring_tcf2-0/">Cookieverklaring</a>
          <a href="https://www.mediahuisnoord.nl/privacy/">Privacyverklaring</a>
          <a href="#" onClick={handlePrivacySettings}>Privacyvoorkeuren</a>
        </div>
      </div>
    </footer>
    <footer className="footer__meta">

      <div className="">
        <div>&copy; {year} Mensenlinq</div>
        <div>Onderdeel van Mediahuis Noord</div>
        <div>
          <a href="https://www.mediahuisnoord.nl/verkoopvoorwaarden">Algemene voorwaarden</a> - <a href="https://www.mediahuisnoord.nl/disclaimer">Disclaimer</a>
        </div>
      </div>

    </footer>


  </>
}


export default Footer
